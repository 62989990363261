<template>
  <main>
    <h3 class="mb-5">Add Reward Pool Item</h3>
    <div class="row">
      <div class="col-md-6">
        <label for="reward-type">Reward Type</label>
        <b-form-select v-model="rewardType" :options="rewardTypes"></b-form-select>
      </div>
      <div class="col-md-6">
        <label for="reward-category">Reward Category</label>

        <b-form-select v-model="rewardCategory">
          <b-form-select-option :value="null">Please select an option</b-form-select-option>
          <b-form-select-option
            v-for="(cat, idx) in rewardPoolCategories"
            :key="idx"
            :value="{ id: cat.id, name: cat.name }"
            >{{ cat.name.substring(0, 1).toUpperCase() + cat.name.substring(1) }}</b-form-select-option
          >
        </b-form-select>
      </div>
    </div>
    <div class="row mt-3 align-items-end" v-if="rewardType && rewardCategory">
      <template v-if="rewardType === '0' || rewardType === '1'">
        <div class="col-md-6">
          <label for="amount">Amount</label>
          <b-form-input
            v-model="amount"
            type="number"
            min="1"
            max="999"
            class="w-100"
            placeholder="Amount"
          ></b-form-input>
        </div>
      </template>
      <template v-if="rewardType === '0'">
        <div class="col-md-6">
          <b-form-select v-model="selected">
            <b-form-select-option :value="null">Please select an option</b-form-select-option>
            <b-form-select-option v-for="(opt, idx) in list" :key="idx" :value="opt">{{ opt }}</b-form-select-option>
          </b-form-select>
        </div>
      </template>
      <template v-if="rewardType === '2'">
        <div class="col-md-6">
          <label for="duration">Duration</label>
          <b-form-select v-model="duration" :options="durations"></b-form-select>
        </div>
        <div class="col-md-6">
          <label for="multiplier">Multiplier</label>
          <b-form-select v-model="multiplier" :options="multipliers"></b-form-select>
        </div>
      </template>
    </div>
    <div class="mt-5 d-flex justify-content-center">
      <button @click="onSubmit" :disabled="isDisabled" class="btn w-10 btn-primary">Save</button>
    </div>
  </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  created() {
    this.getRewardPoolCategories();
  },
  data() {
    return {
      amount: null,
      duration: null,
      multiplier: null,
      rewardType: null,
      rewardCategory: null,
      selected: null,
      list: [
        '',
        'K',
        'M',
        'B',
        'T',
        'aa',
        'ab',
        'ac',
        'ad',
        'ae',
        'af',
        'ag',
        'ah',
        'ai',
        'aj',
        'ak',
        'al',
        'am',
        'an',
        'ao',
        'ap',
        'aq',
        'ar',
        'as',
        'at',
        'au',
        'av',
        'aw',
        'ax',
        'ay',
        'aZ',
        'ba',
        'bb',
        'bc',
        'bd',
        'be',
        'bf',
        'bg',
        'bh',
        'bi',
        'bj',
        'bk',
        'bl',
        'bm',
        'bn',
        'bo',
        'bp',
        'bq',
        'br',
        'bs',
        'bt',
        'bu',
        'bv',
        'bw',
        'bx',
        'by',
        'bZ',
      ],
      rewardTypes: [
        { value: null, text: 'Please select an option' },
        { value: '0', text: 'Cash' },
        { value: '1', text: 'Super Cash' },
        { value: '2', text: 'Boost' },
      ],
      durations: [
        { value: null, text: 'Please select an option' },
        { value: 15, text: '15 mins' },
        { value: 30, text: '30 mins' },
        { value: 60, text: '1 hr' },
        { value: 120, text: '2 hrs' },
        { value: 180, text: '3 hrs' },
        { value: 360, text: '6 hrs' },
        { value: 720, text: '12 hrs' },
        { value: 1440, text: '1 day' },
        { value: 4320, text: '3 days' },
        { value: 10080, text: '7 days' },
      ],
      multipliers: [
        { value: null, text: 'Please select an option' },
        { value: 2, text: '2' },
        { value: 3, text: '3' },
        { value: 4, text: '4' },
        { value: 5, text: '5' },
        { value: 10, text: '10' },
        { value: 20, text: '20' },
        { value: 50, text: '50' },
        { value: 100, text: '100' },
      ],
    };
  },
  methods: {
    ...mapActions({
      getRewardPoolCategories: 'getRewardPoolCategories',
      saveRewardPoolItem: 'saveRewardPoolItem',
    }),
    onSubmit() {
      const data = {
        rewardPoolItem: {
          amount: this.amount ? this.amount + ' ' + (this.selected ? this.selected : '') : 0,
          duration: this.duration ? this.duration * 60000 : 0,
          multiplier: this.multiplier ? this.multiplier : 0,
          reward_type: this.rewardType,
          rewardPoolCategory: this.rewardCategory,
        },
      };

      this.saveRewardPoolItem({
        data: data,
        vm: this,
      });
    },
    resetFields() {
      this.amount = null;
      this.duration = null;
      this.multiplier = null;
      this.rewardType = null;
      this.rewardCategory = null;
      this.selected = null;
    },
  },
  computed: {
    ...mapGetters({
      rewardPoolCategories: 'rewardPoolCategories',
    }),
    isDisabled() {
      if (!this.rewardType || !this.rewardCategory) {
        return true;
      } else {
        if (
          ((this.rewardType === '0' || this.rewardType === '1') && (!this.amount || this.amount < 1)) ||
          (this.rewardType === '2' && (!this.duration || !this.multiplier))
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
main {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  height: fit-content;
  h3 {
    font-size: 16px;
    font-weight: 700;
    color: #3c4eba;
  }

  label {
    color: #3c4eba;
    font-weight: 500;
  }

  button.w-10 {
    width: 10%;
  }
}
</style>
